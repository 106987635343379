<template>
  <div class="social-links">
    <a href="https://x.com/Cannon_cow" target="_blank">X (Twitter)</a>
    <a href="https://www.facebook.com/Cannoncow/" target="_blank">Facebook</a>
    <a href="https://www.instagram.com/cannoncow/" target="_blank">Instagram</a>
    <a href="https://www.youtube.com/@cannoncow5757" target="_blank">YouTube</a>
    <a href="https://discord.gg/EdpYJgpF" target="_blank">Discord</a>
    <a href="https://www.tumblr.com/cannoncow" target="_blank">Tumblr</a>
    <a href="https://www.tiktok.com/@cannoncow" target="_blank">TikTok</a>

    <!-- Add more links as needed -->
  </div>
</template>

<script>
export default {
  name: 'SocialLinks'
}
</script>

<style scoped>
.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 20px;
}

a {
  color: #3b5998; /* Adjust color as needed */
  text-decoration: none;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0; /* Optional: add background for better visibility */
}

a:hover {
  background-color: #ddd; /* Optional: add hover effect */
}
</style>
