import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/AppHome.vue'

// Define your routes
const routes = [
  { path: '/', component: Home }
]

// Create the router instance
const router = createRouter({
  history: createWebHistory(),
  routes
})

// Create and mount the Vue instance
createApp(App).use(router).mount('#app')
