<template>
  <footer class="footer">
    <p>&copy; 2024 The Glitch Fairy. All rights reserved.</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
.footer {
  text-align: center;
  padding: 10px 0;
  background-color: #f1f1f1;
}
</style>
