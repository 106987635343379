<template>
  <header class="header">
    <img src="@/assets/images/banner.png" alt="The Glitch Fairy" class="banner">
  </header>
</template>

<script>
export default {
  name: 'AppHeader'
}
</script>

<style scoped>
.header {
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  width: 70%; /* Set the width to 70% of the page */
  max-width: 100%; /* Ensure it doesn't overflow the container */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures the full image is visible */
  border: 5px solid #fff; /* Add a white border */
  border-radius: 15px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

@media (max-width: 768px) {
  .banner {
    width: 90%; /* Increase the width on smaller screens */
  }
}
</style>
